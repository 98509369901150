import { formatInTimeZone } from "date-fns-tz";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import data from "../assets/chart-data.json";

const pluralize = (word: string, num: number) => {
  if (num === 1) {
    return word;
  }

  return `${word}s`;
};

export function ScaleChart() {
  return (
    <div className="divide-brand-red/50 divide-y">
      <div>
        <div className="h-[200px]">
          <ResponsiveContainer>
            <AreaChart
              syncId="autoscaler"
              data={data.throughput}
              margin={{
                top: 24,
                right: 24,
                left: -20,
                bottom: 8,
              }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#EA2804" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#EA2804" stopOpacity={0} />
                </linearGradient>
              </defs>

              <Tooltip
                content={(props) => (
                  <ChartTooltip {...props} unit="prediction" />
                )}
                isAnimationActive={false}
              />
              <CartesianGrid
                stroke="white"
                strokeOpacity={0.2}
                horizontal
                vertical={false}
                strokeDasharray="3 3"
              />
              <YAxis
                className="text-xs font-mono"
                style={{
                  fill: "rgba(255, 255, 255, .5)",
                }}
                domain={["dataMin", "dataMax"]}
              />
              <XAxis
                className="text-xs font-mono text-center"
                dataKey="x"
                padding={{
                  left: 16,
                  right: 16,
                }}
                style={{
                  fill: "rgba(255, 255, 255, .5)",
                }}
                interval="preserveStartEnd"
                tickMargin={8}
                ticks={data.throughput
                  .filter((_, i) => {
                    const interval = Math.floor(data.throughput.length / 5);
                    return (
                      i % interval === 0 || i === data.throughput.length - 1
                    );
                  })
                  .map((d) => d.x)}
                tickFormatter={(tick) =>
                  `${formatInTimeZone(tick, "UTC", "HH:mm")} UTC`
                }
              />
              <Area
                dataKey={"y"}
                fill={"url(#gradient)"}
                stroke="#EA2804"
                fillOpacity={1}
                isAnimationActive={false}
                stackId="bar"
                type="monotone"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="px-3 pt-3">
          <p className="text-sm tracking-tight font-semibold">
            Prediction throughput{" "}
            <span className="font-normal">(requests per second)</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const ChartTooltip = ({
  active,
  payload,
  unit,
}: React.ComponentProps<typeof Tooltip> & { unit?: string }) => {
  if (active && payload && payload.length) {
    // const num = Number(payload[0].value);
    const { x, y } = payload[0].payload as {
      x: number;
      y: number;
    };
    const labelAsDate = new Date(x);

    return (
      <div className="max-w-[12rem] border border-brand-red bg-black text-white py-2.5 px-3">
        <span className="text-r8-gray-11 text-xs">
          {formatInTimeZone(labelAsDate, "UTC", "HH:mm")} UTC
        </span>
        <p className="text-base">
          {y} {unit ? pluralize(unit, y) : null}
        </p>
      </div>
    );
  }

  return null;
};
